import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { breakpoints, color, formsAutoFillingStyles } from '../../../utils/style';
import IcomoonIcon from '../IcomoonIcon';
import { isValidField } from '../../../utils/system';
import { bankingMakeDepositQR, getUserIpAddress } from '../../../utils/api/banking';
import dictionary from '../../../utils/dictionary';

const Wrapper = styled('div')`
  ${formsAutoFillingStyles};
  .fields-row {
    display: flex;
    align-items: center;
    @media (max-width: ${breakpoints.lg}) {
      flex-wrap: wrap;
    }
    .wrap-bonus {
      display: flex;
      align-items: center;
      svg {
        flex: inherit;
        text-align: right;
        margin-left: auto;
      }
    }
    .fieldWrap {
      position: relative;
      width: 100%;
      svg {
        position: absolute;
        right: 10px;
        top: 14px;
      }
      &:first-of-type {
        margin-right: 8px;
        @media (max-width: ${breakpoints.lg}) {
          margin-right: 0px;
        }
      }

      & > div {
      }

      & select {
        cursor: pointer;
      }
      &.singleField {
        margin-right: 0;
      }
    }
  }  

  .transaction-label {
    height: 96px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${color.lineGrey};
    border-radius: 8px;
    margin-bottom: 16px;
    span {
      margin-left: 6px;
    }
  }

  #errorMsg,
  #successMsgBox {
    display: none;

    &.visible {
      display: block;
    }
  }
  
  .step-subtext {
    margin-bottom: 25px;
    p {
      margin-bottom: 5px;
      display: flex;
      align-items: top;
      font-size: 14px;
    }
    .number {
      display: inline-flex;
      background: ${color.darkPink};
      color: ${color.black};
      align-items: center;
      justify-content: center;
      margin: 4px 10px 0 0;
      font-size: 11px;
      line-height: 1;
      width: 16px;
      height: 16px;
      min-width: 16px;
      border-radius: 8px;
    }
    .text {
      font-size: 14px;
      opacity: 0.7;
    }
  }
  .qr-iframe-wrapper {
    background: rgb(31, 44, 47);
    padding-bottom: 25px;
  }
  
  .qr-iframe {
    min-height: 600px;    
    border: none;
    display: block;
    margin: 0 auto;
  }
  @media (max-width: ${breakpoints.xxs}) {
    .qr-iframe-wrapper {
      margin: 0 -30px;
    }
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
`;

const Button = styled('button')`
  width: 100%;
  height: 44px;
  line-height: 40px;
  font-size: 16px;
  background: ${color.darkPink}!important;

  &:disabled {
    background-color: #a30032 !important;
    opacity: 0.6;
  }
  &:hover {
    background-color: #a30032 !important;
  }
  &:active {
    background-color: rgba(201, 0, 62, 0.5) !important;
  }

  span {
    font-size: 16px;
    text-transform: capitalize;
  }
  &.green-button {
    outline: none;
    margin: 0;
  }
`;

const hideMsg = () => {
  const successMsg = document.getElementById('successMsgBox');
  successMsg.classList.remove('visible');
  const errorMsg = document.getElementById('errorMsg');
  errorMsg.classList.remove('visible');
};

const showErrorMsg = (msg) => {
  hideMsg();
  const errorMsg = document.getElementById('errorMsg');
  if (msg) errorMsg.innerText = msg;
  errorMsg.classList.add('visible');
};

const Deposit = ({ data }) => {
  const [validating, setValidating] = useState(false);
  const [userIp, setUserIp] = useState('');
  const [qrIframe, setQrIframe] = useState('');

  useEffect(() => {
    getUserIpAddress(setUserIp);
  });

  const depositSubmit = (values, { resetForm }) => {
    const valuesObject = { ...values };

    valuesObject.amount = +values.amount;
    valuesObject.customerBankAccountId = +values.customerBankAccountId;
    if (values.bonusId) {
      valuesObject.bonusId = +values.bonusId;
    } else {
      delete valuesObject.bonusId;
    }
    valuesObject.remoteIP = userIp;
    const requestData = {
      ...valuesObject,
    };

    bankingMakeDepositQR(requestData)
      .then((json) => {
        const jsonString = JSON.parse(json);
        if (!json) {
          showErrorMsg('คำขอไม่ถูกต้อง.');
          resetForm({});
        }

        if ('QRCodeData' in jsonString && jsonString.QRCodeData.qrCodePageUrl) {
          resetForm({});
          setQrIframe(jsonString.QRCodeData.qrCodePageUrl);
        } else {
          resetForm({});
          showErrorMsg(
            `บัญชีมีการทำธุรกรรมการฝากเงินที่ดำเนินการอยู่ โปรดรอสักครู่
              เราดำเนินการธุรกรรมของคุณ หากการฝากเงินของคุณไม่ได้รับเครดิตภายใน5 นาทีโปรดติดต่อคอลเซ็นเตอร์ของเรา`,
          );
        }
      })
      .catch((err) => {
        console.error('Banking Deposit request: ', err);
      });
  };

  const submitButton = () => setValidating(true);

  const defaultOption = (
    <option key="default" defaultValue>
      เลือกที่นี่
    </option>
  );

  const { results: customerBankAccountResults = [] } = data.customerBankAccountsDropDown || {};
  const customerBankAccounts = customerBankAccountResults?.map((i) => (
    <>
      {i.text ? (
        <option key={i.id} value={i.id}>
          {i.text} {i.bankAccountNumber} {i.bankAccountName}
        </option>
      ) : null}
    </>
  ));
  const customBankAccountOptions = [defaultOption, ...customerBankAccounts];
  /* Start new bonuses */
  const { results: bonusesDropDown = [] } = data.bonusesDropDown || {};
  const bonusIds = bonusesDropDown?.map((i) => (
    <option key={i.id} value={i.id}>
      {i.text}
    </option>
  ));
  const bonusIdOptions = [defaultOption, ...bonusIds];

  const initialValues = {
    amount: 0,
    customerBankAccountId: '',
    bonusId: '',
  };

  return (
    <Wrapper>
      {qrIframe ?
        (
          <div className="qr-iframe-wrapper">
            <iframe id="qr-iframe" className="qr-iframe" title="QR Payment" src={qrIframe} />
          </div>
        )
        : (
          <Formik
            initialValues={initialValues}
            onSubmit={depositSubmit}
            validationSchema={Yup.object()
              .shape({
                amount: Yup.number().integer('ใส่ยอดจำนวนเต็มเท่านั้น')
                  .typeError('จำนวนที่ต้องการ')
                  .min(1, 'Enter 1 or more')
                  .required('จำนวนที่ต้องการ'),
                customerBankAccountId: Yup.string()
                  .required('จำเป็นต้องใช้'),
                bonusId: Yup.string()
                  .required('จำเป็นต้องใช้'),
              })}
          >
            {({
              errors,
              status,
              touched,
              isSubmitting,
              isValid,
              values,
            }) => (
              <Form id="depositForm">
                <div id="errorMsg" className="errorMsg">
                  There has been an error!
                </div>
                <div id="successMsgBox" className="successMsg">
                  <IcomoonIcon icon="check" color="#ffcdcd" size={46} alt="deposit" />
                  <div className="successMsgText">
                    <p>
                      ส่งแบบฟอร์มการฝากของคุณสำเร็จแล้ว โปรดรอขณะที่เราดำเนินการธุรกรรมของคุณ
                      หากการฝากเงินของคุณไม่ได้รับเครดิตภายใน 5 นาทีโปรดติดต่อคอลเซ็นเตอร์ของเรา
                    </p>
                    <p className="small">
                      <a href={dictionary.lineLink}>LineID {dictionary.lineWithAtSign}</a>
                    </p>
                  </div>
                </div>
                <div className="step-subtext">
                  <p className="text">{dictionary.qrDescLine1}</p>
                  <p className="text">{dictionary.qrDescLine2}</p>
                  <p>
                    <span className="number">1</span>
                    <span className="text">{dictionary.qrStep1}</span>
                  </p>
                  <p>
                    <span className="number">2</span>
                    <span className="text">{dictionary.qrStep2}</span>
                  </p>
                  <p>
                    <span className="number">3</span>
                    <span className="text">{dictionary.qrStep3}</span>
                  </p>
                  <p>
                    <span className="number">4</span>
                    <span className="text">{dictionary.qrStep4}</span>
                  </p>
                </div>
                <div className="fields-row">
                  <div className="fieldWrap iconField">
                    <Field
                      type="text"
                      name="amount"
                      placeholder="100"
                      id="withdraw-amount"
                      value={values.amount || ''}
                    />
                    <IcomoonIcon icon="B" size={14} alt="b" />
                    {isValidField('amount', errors, touched, validating) &&
                    <div className="error">! {errors.amount}</div>}
                  </div>
                  <div className="fieldWrap iconField">
                    <Field
                      component="select"
                      name="customerBankAccountId"
                      placeholder="Your account number"
                      data-validate="required,minSelected[1]"
                      id="accountNo"
                    >
                      {customBankAccountOptions}
                    </Field>
                    {isValidField('customerBankAccountId', errors, touched, validating) && (
                      <div className="error">
                        ! {isValidField('customerBankAccountId', errors, touched, validating)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="fields-row">
                  <div className="fieldWrap iconField singleField">
                    <Field
                      component="select"
                      name="bonusId"
                      placeholder="Choose your bonus"
                      id="bonusId"
                    >
                      {bonusIdOptions}
                    </Field>
                    {isValidField('bonusId', errors, touched, validating) && (
                      <div className="error">! {isValidField('bonusId', errors, touched, validating)}</div>
                    )}
                  </div>
                </div>
                <BtnWrapper>
                  <Button
                    aria-label="next"
                    className="green-button"
                    type="submit"
                    disabled={(!isValid || isSubmitting) && validating}
                    onClick={submitButton}
                  >
                    <span>{dictionary.submitDeposit}</span>
                  </Button>
                  {status && status.msg && <div>q{status.msg}</div>}
                </BtnWrapper>
              </Form>
            )}
          </Formik>
        )}

    </Wrapper>
  );
};
export default Deposit;
