import { getBankingToken, request } from './index';
import {
  bankingAuthPATH,
  bankingGetDepositPATH,
  bankingGetWithdrawPATH,
  bankingMakeDepositPATH,
  bankingMakeDepositQrPATH,
  bankingMakeWithdrawPATH,
  userIpFetchUrl,
} from './config';

export const bankingAuth = (username, accountNo) => request({
  path: bankingAuthPATH,
  method: 'POST',
  body: {
    userName: username,
    accountNo,
  },
});

export const bankingGetDeposit = () => request({
  path: bankingGetDepositPATH,
  method: 'POST',
  body: {
    ba_token: getBankingToken(),
  },
});

export const bankingMakeDeposit = (data) => request({
  path: bankingMakeDepositPATH,
  method: 'POST',
  body: {
    data: { ...data },
    ba_token: getBankingToken(),
  },
});

export const bankingMakeDepositQR = (data) => request({
  path: bankingMakeDepositQrPATH,
  method: 'POST',
  body: {
    ...data,
    ba_token: getBankingToken(),
  },
});

export const bankingGetWithdraw = () => request({
  path: bankingGetWithdrawPATH,
  method: 'POST',
  body: {
    ba_token: getBankingToken(),
  },
});

export const bankingMakeWithdraw = ({ amount, custbankaccountId, remarks }) => request({
  path: bankingMakeWithdrawPATH,
  method: 'POST',
  body: {
    data: {
      amount,
      custbankaccountId,
      remarks,
    },
    ba_token: getBankingToken(),
  },
});

export const getUserIpAddress = async (ipStateFunc) => {
  const response = await fetch(userIpFetchUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    const json = await response.json();
    if ('ip' in json) {
      ipStateFunc(json.ip);
    }
  } else {
    console.log('Could not fetch ip');
  }
};
