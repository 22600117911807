import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { color, breakpoints, formsAutoFillingStyles } from '../../../utils/style';
import IcomoonIcon from '../IcomoonIcon';
import { bankingMakeWithdraw } from '../../../utils/api/banking';
import dictionary from '../../../utils/dictionary';
import { isValidField } from '../../../utils/system';

const Wrapper = styled('div')`
  ${formsAutoFillingStyles};
  .fields-row {
    display: flex;
    flex-wrap: wrap;
    .wrap-bonus {
      display: flex;
      align-items: center;
    }
    .fieldWrap {
      position: relative;
      width: 100%;

      svg {
        position: absolute;
        right: 10px;
        top: 14px;
      }

      &:first-of-type {
        @media (max-width: ${breakpoints.xs}) {
          margin-right: 0px;
        }
      }
    }
  }
  .bonus-label {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    border: 1px solid ${color.lineGrey};
    border-radius: 6px;
    cursor: pointer;
    position: relative;
  }
  input[type='checkbox']:checked + .bonus-label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  & textarea {
    padding-top: 10px;
  }

  .transaction-label {
    height: 96px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${color.lineGrey};
    border-radius: 8px;
    margin-bottom: 16px;
    span {
      margin-left: 6px;
    }
  }

  #errorMsgWithdraw,
  #successMsgBoxWithdraw {
    &.visible {
      display: block;
    }
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
`;

const Button = styled('button')`
  width: 100%;
  height: 44px;
  line-height: 40px;
  font-size: 16px;
  outline: none;
  background: ${color.darkPink}!important;

  &:disabled {
    background-color: #a30032 !important;
    opacity: 0.6;
  }
  &:hover {
    background-color: #a30032 !important;
  }
  &:active {
    background-color: rgba(201, 0, 62, 0.5) !important;
  }

  span {
    font-size: 16px;
    text-transform: capitalize;
  }
  &.green-button {
    margin: 0;
  }
`;

const hideMsg = () => {
  const successMsg = document.getElementById('successMsgBoxWithdraw');
  successMsg.classList.remove('visible');
  const errorMsg = document.getElementById('errorMsgWithdraw');
  errorMsg.classList.remove('visible');
};

const showSuccessMsg = () => {
  hideMsg();
  const successMsg = document.getElementById('successMsgBoxWithdraw');
  successMsg.classList.add('visible');
  setTimeout(() => {
    successMsg.classList.remove('visible');
  }, 3000);
};

const showErrorMsg = (msg) => {
  const errorMsg = document.getElementById('errorMsgWithdraw');
  if (msg) errorMsg.innerHTML = msg;
  errorMsg.classList.add('visible');
};

const Withdraw = ({ data }) => {
  const [validating, setValidating] = useState(false);

  const withdrawSubmit = (values, { resetForm }) => {
    bankingMakeWithdraw({
      amount: +values.amount,
      custbankaccountId: values.custBankAccountId,
      remarks: values.remarks,
    })
      .then((json) => {
        if (!json) {
          showErrorMsg('คำขอไม่ถูกต้อง.');
          return false;
        }

        if (json.StatusCode === 200 || json.IsSuccess) {
          resetForm({});
          showSuccessMsg();
          return false;
        }

        switch (json.StatusCode) {
          case 401:
            showErrorMsg('ขออภัยชื่อผู้ใช้หรือบัญชีธนาคารนี้ไม่ถูกต้องหรือไม่มีอยู่ กรุณาใส่รายละเอียดที่ถูกต้อง');
            break;
          case 400:
            showErrorMsg('Bad Request.');
            break;
          case 500:
            showErrorMsg(
              `บัญชีมีการทำธุรกรรมการฝากเงินที่ดำเนินการอยู่ โปรดรอสักครู่
            เราดำเนินการธุรกรรมของคุณ หากการฝากเงินของคุณไม่ได้รับเครดิตภายใน 5 นาทีโปรดติดต่อคอลเซ็นเตอร์ของเรา`,
            );
            break;
          default:
            if (json?.innerType?.ResultMessage) {
              showErrorMsg(json?.innerType?.ResultMessage);
              break;
            }
            if (json?.title) {
              showErrorMsg(json?.title);
              break;
            }
            if (json?.error) {
              showErrorMsg(json?.error);
              break;
            }
            showErrorMsg('Error');
            break;
        }
      })
      .catch((err) => {
        console.error('Withdraw request: ', err);
      });
  };
  const submitButton = () => setValidating(true);

  const initialValues = {
    amount: 500,
    channelId: '8',
    custBankAccountId: '',
    remarks: '',
  };

  const defaultOption = (
    <option selected key="default" defaultValue>
      เลือกที่นี่
    </option>
  );

  const { results = [] } = data.customerBankAccountsDropDown || {};
  const customerBankAccounts = results?.map((i) => (
    <option key={i.id} value={i.id}>
      {i.text}
    </option>
  ));
  const customBankAccountOptions = [defaultOption, ...customerBankAccounts];

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={withdrawSubmit}
        validationSchema={Yup.object().shape({
          amount: Yup.number().integer('ใส่ยอดจำนวนเต็มเท่านั้น').typeError('จำนวนที่ต้องการ').min(1, 'Enter 1 or more')
            .required('จำนวนที่ต้องการ'),
          custBankAccountId: Yup.string().required('จำเป็นต้องใช้'),
        })}
      >
        {({ errors, status, touched, isValid, isSubmitting, values }) => (
          <Form id="depositForm">
            <div id="errorMsgWithdraw" className="errorMsg">
              {dictionary.formSentFailed}
            </div>
            <div id="successMsgBoxWithdraw" className="successMsg">
              <IcomoonIcon icon="check" color="#ffcdcd" size={46} alt="check" />
              <div className="successMsgText">
                <p>
                  ส่งคำขอถอนเงินเรียบร้อย กรุณารอสักครู่ขณะทางเราดำเนินกระบวนการให้กับท่าน หากจำนวนเงินของท่านไม่ปรากฎใน
                  10 นาที โปรดติดต่อฝ่ายดูแลลูกค้า
                </p>
                <p className="small">
                  <a href={dictionary.lineLink}>LineID {dictionary.lineWithAtSign}</a>
                </p>
              </div>
            </div>
            <div className="fields-row">
              <div className="fieldWrap iconField">
                <Field type="text" name="amount" placeholder="100" id="withdraw-amount" value={values.amount || ''} />
                <IcomoonIcon icon="B" size={14} alt="b" />
                {isValidField('amount', errors, touched, validating) && (
                  <div className="error">! {isValidField('amount', errors, touched, validating)}</div>
                )}
              </div>
              <div className="fieldWrap iconField">
                <Field
                  component="select"
                  name="custBankAccountId"
                  placeholder="Your account number"
                  id="acc_number"
                  data-validate="required,minSelected[1]"
                >
                  {customBankAccountOptions}
                </Field>
                {isValidField('custBankAccountId', errors, touched, validating) && (
                  <div className="error">! {isValidField('custBankAccountId', errors, touched, validating)}</div>
                )}
              </div>
            </div>
            <div className="fieldWrap text-area">
              {errors.remarks && touched.remarks && <div className="error-new">{errors.remarks}</div>}
              <Field
                component="textarea"
                name="remarks"
                placeholder="โปรดระบุ (ถ้ามี)"
                id="withdraw-remarks"
                value={values.remarks || ''}
              />
            </div>
            <BtnWrapper>
              <Button
                aria-label="next"
                className="green-button"
                type="submit"
                disabled={(!isValid || isSubmitting) && validating}
                onClick={submitButton}
              >
                <span>{dictionary.submitWithdraw}</span>
              </Button>
              {status && status.msg && <div>{status.msg}</div>}
            </BtnWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default Withdraw;
