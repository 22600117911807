import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import { color, breakpoints, formsAutoFillingStyles } from '../../../utils/style';
import 'react-datepicker/dist/react-datepicker.css';
import IcomoonIcon from '../IcomoonIcon';
import { buildDate, buildTime, isValidField } from '../../../utils/system';
import { bankingMakeDeposit } from '../../../utils/api/banking';
import dictionary from '../../../utils/dictionary';

const Wrapper = styled('div')`
  ${formsAutoFillingStyles};

  .react-datepicker-wrapper,
  .labelField {
    width: 100%;
  }
  .fileUploadField {
    position: relative;
    height: 100%;
    cursor: pointer;
    input {
      display: none;
    }
    img {
      margin-bottom: 16px;
    }
    .loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 10px;
      text-align: center;
    }
  }

  .fileUploadLabel {
    height: 96px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${color.lineGrey};
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;
    span {
      margin-left: 6px;
    }
    svg {
      margin-right: 10px;
      opacity: 0.5;
    }
    &:hover,
    &:active {
      border-color: ${color.white};
      color: ${color.white};
      svg {
        opacity: 1;
      }
    }
  }
  .fields-row {
    display: flex;
    align-items: center;
    @media (max-width: ${breakpoints.lg}) {
      flex-wrap: wrap;
    }
    .wrap-bonus {
      display: flex;
      align-items: center;
      svg {
        flex: inherit;
        text-align: right;
        margin-left: auto;
      }
    }
    .fieldWrap {
      position: relative;
      width: 100%;
      svg {
        position: absolute;
        right: 10px;
        top: 14px;
      }
      &:first-of-type {
        margin-right: 8px;
        @media (max-width: ${breakpoints.lg}) {
          margin-right: 0;
        }
      }

      & > div {
      }

      & select {
        cursor: pointer;
      }
      &.singleField {
        margin-right: 0;
      }
    }
    .wrap-bonus {
      justify-content: space-between;
      .popup-info {
        position: relative;
        top: -13px;
        left: -10px;
        width: 10px;
        height: 10px;
        svg {
          position: static;
        }
        .popup-info-text {
          position: absolute;
          font-size: 12px;
          background-color: ${color.middlelightPurple};
          border-radius: 8px;
          min-width: 100px;
          padding: 10px;
          right: -8px;
          bottom: 15px;
          display: none;
          &:before {
            content: '';
            position: absolute;
            display: block;
            right: 6px;
            bottom: -14px;
            border: 7px solid transparent;
            height: 0;
            width: 0;
            border-top-color: ${color.middlelightPurple};
          }
        }
        &:hover .popup-info-text {
          display: block;
        }

        @media (max-width: ${breakpoints.md}) {
          top: -13px;
          left: -10px;
        }
      }
    }
  }
  .bonus-label {
    display: flex;
    position: relative;
    cursor: pointer;
  }
  .bonus-label:before {
    content: '';
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid ${color.lineGrey};
    border-radius: 6px;
    cursor: pointer;
    position: relative;
  }
  input[type='checkbox']:checked + .bonus-label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .transaction-label {
    height: 96px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${color.lineGrey};
    border-radius: 8px;
    margin-bottom: 16px;
    span {
      margin-left: 6px;
    }
  }

  #errorMsg,
  #successMsgBox {
    display: none;

    &.visible {
      display: block;
    }
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
`;

const Button = styled('button')`
  width: 100%;
  height: 44px;
  line-height: 40px;
  font-size: 16px;
  background: ${color.darkPink}!important;

  &:disabled {
    background-color: #a30032 !important;
    opacity: 0.6;
  }
  &:hover {
    background-color: #a30032 !important;
  }
  &:active {
    background-color: rgba(201, 0, 62, 0.5) !important;
  }

  span {
    font-size: 16px;
    text-transform: capitalize;
  }
  &.green-button {
    outline: none;
    margin: 0;
  }
`;

const fileToBase64 = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    if (file && file.type.match('image.*')) {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    }
  });

class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      thumb: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.file !== prevState.file) {
      return { thumb: nextProps.file };
    }
    return null;
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;
    if (!file) {
      return null;
    }
    if (loading) {
      return <p key="loading">loading...</p>;
    }
    return <img src={thumb} key="image" alt="uploaded file" className="img-thumbnail mt-2" />;
  }
}

const hideMsg = () => {
  const successMsg = document.getElementById('successMsgBox');
  successMsg.classList.remove('visible');
  const errorMsg = document.getElementById('errorMsg');
  errorMsg.classList.remove('visible');
};

const showSuccessMsg = () => {
  hideMsg();
  const successMsg = document.getElementById('successMsgBox');
  successMsg.classList.add('visible');
  setTimeout(() => {
    successMsg.classList.remove('visible');
  }, 3000);
};

const showErrorMsg = (msg) => {
  hideMsg();
  const errorMsg = document.getElementById('errorMsg');
  if (msg) errorMsg.innerText = msg;
  errorMsg.classList.add('visible');
};

const Deposit = ({ data }) => {
  const [validating, setValidating] = useState(false);

  const depositSubmit = (values, { resetForm }) => {
    /*
     console.log(values);
    */
    const date = new Date(values.transactiondate);
    const time = new Date(values.transactiontime);
    const requestData = {
      ...values,
      // remarks: values.bonus ? `YES Bonus. PROMO CODE: ${values.promocode}.` : 'NO Bonus.',
      date: buildDate(date, '/'),
      time: buildTime(time, '.'),
      amount: +values.amount,
      bonusId: +values.bonusId,
      base64Image: values.base64Image.split('base64,')[1],
    };
    delete requestData.transactiondate;
    delete requestData.transactiontime;
    // delete requestData.bonus;

    bankingMakeDeposit(requestData)
      .then((json) => {
        if (!json) {
          showErrorMsg('คำขอไม่ถูกต้อง.');
          return false;
        }

        if (json.StatusCode === 200 && json?.IsSuccess === true) {
          resetForm({});
          showSuccessMsg();
          return false;
        }

        switch (json.StatusCode) {
          case 401:
            showErrorMsg('ขออภัยชื่อผู้ใช้หรือบัญชีธนาคารนี้ไม่ถูกต้องหรือไม่มีอยู่ กรุณาใส่รายละเอียดที่ถูกต้อง');
            break;
          case 400:
            showErrorMsg('คำขอไม่ถูกต้อง.');
            break;
          case 500:
            showErrorMsg(
              `บัญชีมีการทำธุรกรรมการฝากเงินที่ดำเนินการอยู่ โปรดรอสักครู่
              เราดำเนินการธุรกรรมของคุณ หากการฝากเงินของคุณไม่ได้รับเครดิตภายใน5 นาทีโปรดติดต่อคอลเซ็นเตอร์ของเรา`,
            );
            break;
          default:
            if (json?.innerType?.Message.includes('NotifyAllTransactionCreated')) {
              showSuccessMsg();
            } else {
              showErrorMsg(
                'บัญชีมีการทำธุรกรรมการฝากเงินที่ดำเนินการอยู่ โปรดรอสักครู่ เราดำเนินการธุรกรรมของคุณ หากการฝากเงินของคุณไม่ได้รับเครดิตภายใน 5 นาทีโปรดติดต่อคอลเซ็นเตอร์ของเรา',
              );
              break;
            }
        }
      })
      .catch((err) => {
        console.error('Banking Deposit request: ', err);
      });
  };

  const submitButton = () => setValidating(true);

  const defaultOption = (
    <option key="default" defaultValue>
      เลือกที่นี่
    </option>
  );

  const { results: channelResults = [] } = data.depositChannelsDropDown || {};
  const depositChannelIdOptionArray = channelResults?.map((i) => (
    <option key={`deposit-${i.id}`} value={i.id}>
      {i.text}
    </option>
  ));
  const depositChannelId = [defaultOption, ...depositChannelIdOptionArray];

  const { results: customerBankAccountResults = [] } = data.customerBankAccountsDropDown || {};
  const customerBankAccounts = customerBankAccountResults?.map((i) => (
    <>
      {i.text ? (
        <option key={`bank-account-${i.id}`} value={i.id}>
          {i.text} {i.bankAccountNumber} {i.bankAccountName}
        </option>
      ) : null}
    </>
  ));
  const customBankAccountOptions = [defaultOption, ...customerBankAccounts];

  const { results: bankAccountResults = [] } = data.bankAccountsDropDown || {};
  const bankAccounts = bankAccountResults?.map((i) => (
    <option key={`banking-${i.id}`} value={i.id}>
      {i.bankName} {i.bankAccountNumber}

    </option>
  ));
  const bankAccountsOptions = [defaultOption, ...bankAccounts];
  /* Start new bonuses */
  const { results: bonusesDropDown = [] } = data.bonusesDropDown || {};
  const bonusIds = bonusesDropDown?.map((i) => (
    <option key={`bonuses-${i.id}`} value={i.id}>
      {i.text}
    </option>
  ));
  const bonusIdOptions = [defaultOption, ...bonusIds];
  /* End new bonuses */

  const initialValues = {
    amount: 0,
    depositChannelId: '',
    bankaccountId: '',
    customerBankAccountId: '',
    transactiondate: new Date(),
    transactiontime: new Date().getHours(),
    base64Image: '',
    bonusId: '',
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={depositSubmit}
        validationSchema={Yup.object().shape({
          amount: Yup.number().integer('ใส่ยอดจำนวนเต็มเท่านั้น')
            .typeError('จำนวนที่ต้องการ')
            .min(1, 'Enter 1 or more')
            .required('จำนวนที่ต้องการ'),
          depositChannelId: Yup.string().required('จำเป็นต้องใช้'),
          base64Image: Yup.string().required('จำเป็นต้องใช้'),
          customerBankAccountId: Yup.string().required('จำเป็นต้องใช้'),
          transactiondate: Yup.date().required('จำเป็นต้องใช้'),
          bankaccountId: Yup.string().required('จำเป็นต้องใช้'),
          bonusId: Yup.string().required('จำเป็นต้องใช้'),
        })}
      >
        {({ errors, status, touched, isSubmitting, isValid, values, setFieldValue }) => (
          <Form id="depositForm" key="deposit-form">
            <div id="errorMsg" className="errorMsg">
              There has been an error!
            </div>
            <div id="successMsgBox" className="successMsg">
              <IcomoonIcon icon="check" color="#ffcdcd" size={46} alt="deposit" />
              <div className="successMsgText">
                <p>
                  ส่งแบบฟอร์มการฝากของคุณสำเร็จแล้ว โปรดรอขณะที่เราดำเนินการธุรกรรมของคุณ
                  หากการฝากเงินของคุณไม่ได้รับเครดิตภายใน 5 นาทีโปรดติดต่อคอลเซ็นเตอร์ของเรา
                </p>
                <p className="small">
                  <a href={dictionary.lineLink}>LineID {dictionary.lineWithAtSign}</a>
                </p>
              </div>
            </div>
            <div className="fields-row">
              <div className="fieldWrap iconField">
                <Field type="text" name="amount" placeholder="100" id="withdraw-amount" value={values.amount || ''} />
                <IcomoonIcon icon="B" size={14} alt="b" />
                {isValidField('amount', errors, touched, validating) && <div className="error">! {errors.amount}</div>}
              </div>
              <div className="fieldWrap iconField">
                <Field component="select" name="depositChannelId" placeholder="Deposit Method" id="deposit_method">
                  {depositChannelId}
                </Field>
                {isValidField('depositChannelId', errors, touched, validating) && (
                  <div className="error">! {isValidField('depositChannelId', errors, touched, validating)}</div>
                )}
              </div>
            </div>
            <div className="fields-row">
              <div className="fieldWrap iconField">
                <Field
                  component="select"
                  name="customerBankAccountId"
                  placeholder="Your account number"
                  data-validate="required,minSelected[1]"
                  id="accountNo"
                >
                  {customBankAccountOptions}
                </Field>
                {isValidField('customerBankAccountId', errors, touched, validating) && (
                  <div className="error">! {isValidField('customerBankAccountId', errors, touched, validating)}</div>
                )}
              </div>
              <div className="fieldWrap iconField">
                <Field component="select" name="bankaccountId" placeholder="Company Bank Account" id="bank_number">
                  {bankAccountsOptions}
                </Field>
                {isValidField('bankaccountId', errors, touched, validating) && (
                  <div className="error">! {isValidField('bankaccountId', errors, touched, validating)}</div>
                )}
              </div>
            </div>
            <div className="fields-row">
              <div className="fieldWrap iconField">
                <IcomoonIcon icon="calendar" color={color.white} size={16} alt="calendar" />
                {errors.transactiondate && touched.transactiondate && (
                  <div className="error-new">{errors.transactiondate}</div>
                )}
                <DatePicker
                  selected={values.transactiondate}
                  dateFormat="MMMM d, yyyy"
                  className="form-control"
                  name="transactiondate"
                  maxDate={new Date()}
                  onChange={(date) => setFieldValue('transactiondate', date)}
                />
              </div>
              <div className="fieldWrap iconField">
                <IcomoonIcon icon="clock" color={color.white} size={18} alt="clock" />
                {errors.transactiontime && touched.transactiontime && (
                  <div className="error-new">{errors.transactiontime}</div>
                )}
                <DatePicker
                  selected={values.transactiontime}
                  className="form-control"
                  name="transactiontime"
                  onChange={(date) => setFieldValue('transactiontime', date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="HH.mm"
                  timeFormat="HH.mm"
                />
              </div>
            </div>
            <div className="fields-row">
              <div className="fieldWrap iconField singleField">
                <Field
                  component="select"
                  name="bonusId"
                  placeholder="Choose your bonus"
                  id="bonusNo"
                >
                  {bonusIdOptions}
                </Field>
                {isValidField('bonusId', errors, touched, validating) && (
                  <div className="error">! {isValidField('bonusId', errors, touched, validating)}</div>
                )}
              </div>
            </div>
            <div className="labelField fileUploadField">
              <label htmlFor="base64Image" className="fileUploadLabel">
                <IcomoonIcon icon="shape" size={26} alt="shape" />
                <span>อัพโหลดสลิปธุรกรรม</span>
              </label>
              {isValidField('base64Image', errors, touched, validating) && (
                <div className="error">! {isValidField('base64Image', errors, touched, validating)}</div>
              )}
              <input
                id="base64Image"
                name="base64Image"
                accept="image/*"
                type="file"
                onChange={(event) => {
                  fileToBase64(event.currentTarget.files[0]).then((result) => setFieldValue('base64Image', result));
                }}
                className="form-control"
              />
              <FileUpload file={values.base64Image} onChange={setFieldValue} name="base64Image" />
            </div>
            <BtnWrapper>
              <Button
                key="send-button"
                aria-label="next"
                className="green-button"
                type="submit"
                disabled={(!isValid || isSubmitting) && validating}
                onClick={submitButton}
              >
                <span>{dictionary.submitDeposit}</span>
              </Button>
              {status && status.msg && <div>q{status.msg}</div>}
            </BtnWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};
export default Deposit;
