import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { color, formsAutoFillingStyles } from '../../../utils/style';
import dictionary from '../../../utils/dictionary';
import { isValidField } from '../../../utils/system';

const Wrapper = styled('div')`
  ${formsAutoFillingStyles};

  .errorMsg {
    background: ${color.red};
    color: ${color.white};
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
    display: none;
    &.visible {
      display: block;
    }
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
`;

const Button = styled('button')`
  width: 100%;
  height: 44px;
  line-height: 44px;
  font-size: 16px;

  &:disabled {
    background-color: #a30032 !important;
    opacity: 0.6;
  }

  span {
    font-size: 16px;
    text-transform: capitalize;
  }
  &.green-button {
    margin: 0;
    background-color: ${color.darkPink};

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      outline: none;
      text-decoration: none;
    }
  }
  &.transparent-button {
    background: none;
    border: 1px solid ${color.white};
    margin: 0;
    &:active,
    &:focus {
      background: none;
      outline: none;
    }
  }
  &:hover {
    background-color: #a30032;
  }
  &:active {
    background-color: rgba(201, 0, 62, 0.5);
  }
`;

const initialValues = {
  userName: '',
  accountNo: '',
};

// REFACTOR
// In the same time with other forms
const PlayerAccount = ({ accountSubmit, activeWallet }) => {
  const [validating, setValidating] = useState(false);

  const submitButton = () => setValidating(true);

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={accountSubmit}
        validationSchema={Yup.object().shape({
          userName: Yup.string()
            .matches(/^[a-z0-9]+$/i, 'กรอกได้เฉพาะตัวอักษรและตัวเลขเท่านั้น')
            .required('จำเป็นต้องใช้'),
          accountNo: Yup.string()
            .matches(/^[-0-9/\s]+$/i, '0-9 และขีดคั่นเท่านั้น')
            .typeError('จำเป็นต้องใช้')
            .min(0, 'positive, please')
            .required('จำเป็นต้องใช้'),
        })}
        validate={(values) => {
          const errors = {};
          if (values.userName && errors.userName) {
            errors.username = 'Alphanumeric please';
          }
          return errors;
        }}
      >
        {({ errors, status, touched, isSubmitting, isValid, values }) => (
          <Form id="loginForm" className={isSubmitting ? 'submitting' : ''}>
            <div id="userDataErrorMsg" className="errorMsg">
              {dictionary.formSentFailed}
            </div>
            <div className="fieldWrap iconField">
              <Field
                type="text"
                name="userName"
                placeholder="ยูสเซอร์เนม"
                id="banking-username"
                className={activeWallet ? 'disable-block' : ''}
                value={values.userName || ''}
              />
              {isValidField('userName', errors, touched, validating) && (
                <div className="error">! {isValidField('userName', errors, touched, validating)}</div>
              )}
            </div>
            <div className="fieldWrap iconField">
              <Field
                type="text"
                name="accountNo"
                placeholder="เลขที่บัญชี"
                id="acc_number"
                className={activeWallet ? 'disable-block' : ''}
                value={values.accountNo || ''}
              />
              {isValidField('accountNo', errors, touched, validating) && (
                <div className="error">! {isValidField('accountNo', errors, touched, validating)}</div>
              )}
            </div>
            <BtnWrapper>
              <Button
                aria-label="next"
                className={activeWallet ? 'transparent-button' : 'green-button'}
                type="submit"
                disabled={!isValid && validating}
                onClick={submitButton}
              >
                <span>{activeWallet ? 'เปลี่ยนบัญชี' : 'ต่อไป'} </span>
              </Button>
              {status && status.msg && <div>{status.msg}</div>}
            </BtnWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default PlayerAccount;
